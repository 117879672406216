import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutImprint from './layouts/LayoutImprint';
import LayoutPrivacy from './layouts/LayoutPrivacy';


// Views 
import Home from './views/Home';
import Imprint from './views/Imprint';
import Privacy from './views/Privacy';

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

   // Route change
   componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/imprint" component={Imprint} layout={LayoutImprint} />
            <AppRoute exact path="/privacy" component={Privacy} layout={LayoutPrivacy} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);