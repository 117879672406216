import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/LogoInvert';
import FooterContact from './partials/FooterContact';
import {Link} from 'react-router-dom';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer invert-color',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top text-xxs">
              <div className="section-inner has-top-divider" style={{ paddingTop: '0px', paddingBottom: '60px' }}></div>
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo />
                  <FooterContact />
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">COMPANY</div>
                  <ul className="list-reset mb-0">
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">RESSOURCES</div>
                  <ul className="list-reset mb-0">
                    <li>
                    <Link to="/imprint">Imprint</Link>
                    </li>
                    <li>
                    <Link to="/privacy">Privacy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </footer >
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;