import React from 'react';
import classNames from 'classnames';

const FooterContact = ({
    className,
    ...props
}) => {

    const Mailto = ({ email, subject, body, children }) => {
        return (
          <a style={{ marginLeft: "15px" }} href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
        );
      };

    const classes = classNames(
        'footer-contact',
        className
    );

    return (
        <div
            {...props}
            className={classes}
        >
            <ul className="list-reset">
                <div className="footer-block">
                    <div className="footer-block-title"></div>
                    <ul className="list-reset mb-0">
                    <li>
                       <span role="img" aria-label="phone">📞 </span> 
                       <a style={{ marginLeft: "10px" }} href="tel:+49-151-15005749">+49 151 15005749</a>
                    </li>
                    <li>
                    <span role="img" aria-label="mail">✉️</span> 
                    <Mailto email=">karl.schreiber@ksc-system.com" subject="" body="">
                             karl.schreiber@ksc-system.com
                    </Mailto>
                    </li>
                    </ul>
                </div>

            </ul>
        </div>
    );
}

export default FooterContact;