import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';


class Imprint extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <GenericSection>
          <div>
            <h2 className="mt-0">Impressum</h2>
            <p>
              
            </p>
            <p>
            Angaben gemäß §5 TMG:
            </p>
            <p>
            Karl Schreiber<br></br>
            Luckenwalder Straße 31<br></br>
            15838 Am Mellensee<br></br>
            USt-IdNr: DE130580011<br></br>
            </p>
            <h4>Geschäftsführer</h4>
            <p>
            Karl Schreiber
            </p>
            <h4>Kontakt</h4>
            <p>
            Karl Schreiber<br></br>
            Luckenwalder Straße 31<br></br>
            15838 Am Mellensee<br></br>
            Telefon: +49 151 15005749<br></br>
            E-Mail: karl.schreiber@ksc-system.com<br></br>
            </p>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default Imprint;