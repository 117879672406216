import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Carousel from '../elements/Carousel';
import CarouselItem from '../elements/CarouselItem';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Features of KSC-SYSTEMS',
      //paragraph: 'Some Text Here.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    KSC-RACE
                  </h3>
                  <p>
                    A luggage system for one or more days. Your whole luggage is behind your body. No disturbing luggage on the back as you would with a regular backpack.
                    No additional air resistance like pannier bags. KSC Systems is a convenient option for multiple days, long distance tours with your race bike.
                    Thanks to the reduced air resistance compared to panniers, you gain a great advantage at higher speeds.
                    The increasing quality of bike paths is making it more attractive to travel with race bikes instead of trekking bikes.
                    KSC Race is optimized for both, weight and air resistance with a smooth and hard outer surface.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/images/Platzhalter.png')}
                    alt="ksc-systems-platzhalter"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    KSC PERFORMANCE
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    High-end KSC Transportation System. Smooth and hard outer surface. Optimized for weight and air resistance. Some parts made from carbon.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/images/Platzhalter.png')}
                    alt="ksc-systems-platzhalter"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    KSC TRAVEL
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  Designed for general use with trekking bikes. 
                  Easy and comfortable luggage transport. 
                  You can feel safe and know that your luggage is always close at hand. For example, during short breaks, museum visits or shopping 
                  - you never lose sight of your luggage. Automatic coupling when you get on - automatic uncoupling when you get off.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">

                  <Carousel>
                    <CarouselItem>
                      <Image
                        className="has-shadow"
                        src={require('../../assets/images/ksc-travel-1.JPG')}
                        alt="ksc-systems-travel_1"
                        width={528}
                        height={396} />
                    </CarouselItem>

                    <CarouselItem>
                      <Image
                        className="has-shadow"
                        src={require('../../assets/images/ksc-travel-2.JPG')}
                        alt="ksc-systems-travel_2"
                        width={528}
                        height={396} />
                    </CarouselItem>
                  </Carousel>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    KSC CARGO
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  Especially, developed for delivery services. Designed for usage with large and heavy backpacks. 
                  The full weight of your backpack is carried by the KSC Trailer. 
                  No more back pain! And you never lose sight of your goods again!
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">

                  <Carousel>
                    <CarouselItem>
                      <Image
                        className="has-shadow"
                        src={require('../../assets/images/ksc-cargo-1.JPG')}
                        alt="ksc-systems-cargo_1"
                        width={528}
                        height={396} />
                    </CarouselItem>

                    <CarouselItem>
                      <Image
                        className="has-shadow"
                        src={require('../../assets/images/ksc-cargo-2.JPG')}
                        alt="ksc-systems-cargo_2"
                        width={528}
                        height={396} />
                    </CarouselItem>

                    <CarouselItem>
                      <Image
                        className="has-shadow"
                        src={require('../../assets/images/ksc-cargo-3.JPG')}
                        alt="ksc-systems-cargo_3"
                        width={528}
                        height={396} />
                    </CarouselItem>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;