import React from 'react';
// import sections
import HeroFull from '../components/sections/HeroFull02';
//import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';

// import some required elements
import Image from '../components/elements/Image';
import Carousel from '../components/elements/Carousel';
import CarouselItem from '../components/elements/CarouselItem';

class Home extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <HeroFull />
        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">KSC-SYSTEMS</h2>
            <p>
              KSC Systems was founded in 2019 in Brandenburg. KSC Systems is a novel luggage transport system for bikes and e-bikes.
            </p>
            <p>
              The basic idea is to combine the advantages of backpacks and panniers.
              Behind the saddle is a support platform, the KSC Supporter, to carry the whole weight of your luggage.
              After mounting your bike, the luggage will be automatically fixed with a high performance magnet (30kg holding force) within the KSC Supporter.
              The connector plate is integrated into your backpack. The connector plate adds less than 100g to your backpack.
              The concept was already tested successfully during various multi-day bike tours.
             </p>
              <p>
              International Patents pending.
            </p>
            <br></br>
              <img
                width="715"
                height="410"
                src={require('../assets/new/rollup_1.jpg')}
                alt="rollup_1">
              </img>
            <br></br>
              <img
                width="715"
                height="410"
                src={require('../assets/new/rollup_2.jpg')}
                alt="rollup_2">
              </img>
            <br></br>
              <img
                width="715"
                height="410"
                src={require('../assets/new/jay_ride.gif')}
                alt="ride">
              </img>
            <br></br>
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/hGmBVEFJBP8?start=1175&end=1306"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
            
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}

export default Home;